import { ImageLayerProps } from "@lidojs/design-editor";
import { RedirectionProps, generateRedirectionHTML } from "../generateRedirectionHTML";
import { generateValidURL } from "../generateValidURL";
import { XMobileFrameHTML } from "../htmlComponents";
import { PageExport, containerPremium } from "./generatePremiumHTML";

export const generateRotate = ({page,hasMobileFrame}: PageExport) => {
  const { ROOT: container, ...components } = page;

  const resources = {
    image1: {
      url: (components.rotate_image1?.props as unknown as ImageLayerProps)?.image?.url,
      hyperlink: components.rotate_image1?.props?.hyperlink,
    },
    image2: {
      url: (components.rotate_image2?.props as unknown as ImageLayerProps)?.image?.url,
      hyperlink: components.rotate_image2?.props?.hyperlink,
    },
    image3: {
      url: (components.rotate_image3?.props as unknown as ImageLayerProps)?.image?.url,
      hyperlink: components.rotate_image3?.props?.hyperlink,
    },
    image4: {
      url: (components.rotate_image4?.props as unknown as ImageLayerProps)?.image?.url,
      hyperlink: components.rotate_image4?.props?.hyperlink,
    },
  };

  const redirectionContainer = generateRedirectionHTML(
    {
      props: container.props.hyperlink,
      id: "ROOT"
    }
  );
  const containerHyperlink = container.props.hyperlink as RedirectionProps;


return `
      <title>Creative Premium: Rotate</title>
      ${rotateStyles}
      ${rotateJS1}
      ${(containerHyperlink?.hasHyperlink && containerHyperlink?.typeLink === "click-tag") ? (
        `<script type="text/javascript">
          var clickTag = "${generateValidURL(
            (container?.props?.hyperlink as RedirectionProps)?.linkRedirect
          )}";
        </script>`
      ) : ''}
    </head>
    <body ${redirectionContainer}>
        ${containerPremium(hasMobileFrame)}
          ${hasMobileFrame ? XMobileFrameHTML : ""}
          <div
            id="wortise-flip-parallax-10"
            class="wortiseFlipParallaxPositionClass10 wortise-flip-parallax first-flip"
          >
            <div class="container">
              <div class="front side">
                <div class="normalize-box">
                  <div class="bg">
                    <picture class="image-standard">
                      <img
                        src="${resources.image1.url}"
                        alt="Image 1"
                      />
                    </picture>
                  </div>
                  <div class="overlay">
                    <div class="overlay-image">
                      <picture class="overlay-image-standard">
                        <img
                          src="${resources.image2.url}"
                          alt="Image 2"
                        />
                      </picture>
                    </div>
                  </div>
                </div>
              </div>
              <div class="back side">
                <div class="normalize-box">
                  <div class="bg">
                    <picture class="image-standard">
                      <img
                        src="${resources.image3.url}"
                        alt="Image 3"
                      />
                    </picture>
                  </div>
                  <div class="overlay">
                    <div class="overlay-image">
                      <picture class="overlay-image-standard">
                        <img
                          src="${resources.image4.url}"
                          alt="Image 4"
                        />
                      </picture>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
         ${hasMobileFrame ? "</div>" : ""}
      </div>
      ${rotateJS2}
`

};


const rotateStyles = `
        <style>
        body {
          background-color: #000000;
        }

        #container {
          overflow: hidden;
          position: relative;
          background-color: #000;
        }
        .stopallanimations * {
          animation-delay: 0s !important;
          animation-duration: 0s !important;
        }
        .wortisemain-page-slide {
          max-width: 100vw;
          max-height: 100vh;
        }
        .disable-perspeftive .wortise-main-swiper-slide {
          -webkit-perspective: initial !important;
          -moz-perspective: initial !important;
          -o-perspective: initial !important;
          perspective: initial !important;
        }

        .wortisemain-page-slide {
          background: #000;
        }
        .hiddenComponent {
          display: none !important;
        }
        #wortiseIcon {
          position: fixed;
          z-index: 99999;
          width: 16px;
          max-width: 11%;
          opacity: 0.7;
          cursor: pointer;
        }
        #wortiseIcon:hover {
          opacity: 1;
        }
        .bottom-left {
          bottom: 2px;
          left: 2px;
        }
        .bottom-right {
          bottom: 2px;
          right: 2px;
        }
        .top-right {
          top: 2px;
          right: 2px;
        }
        .top-left {
          top: 2px;
          left: 2px;
        }

        .wortiseFlipParallaxPositionClass10 {
          top: 50%;
          left: 50%;
          transform: translate3d(-50%, -50%, 0px);
        }

        #wortise-flip-parallax-10 {
          width: 100%;
          height: auto;
          position: absolute;
          display: block;
          z-index: 10;
          filter: drop-shadow(2px 4px 6px rgba(0, 0, 0, 0.3));
        }

        #wortise-flip-parallax-10 .container,
        #wortise-flip-parallax-10 .front,
        #wortise-flip-parallax-10 .back {
          max-height: 100vh;
          max-width: 100vw;
        }

        #wortise-flip-parallax-10 .container {
          width: 100%;
          height: 100%;
          -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
          -webkit-perspective: 1000px;
          perspective: 1000px;
          border-radius: 10px;
          transform: rotateZ(180deg);
        }

        #wortise-flip-parallax-10 .front,
        #wortise-flip-parallax-10 .back {
          background-size: cover;
          background-position: center;
          -webkit-transition: -webkit-transform 1.2s
            cubic-bezier(0.4, 0.2, 0.2, 1);
          transition: -webkit-transform 1.2s cubic-bezier(0.4, 0.2, 0.2, 1);
          -o-transition: transform 1.2s cubic-bezier(0.4, 0.2, 0.2, 1);
          transition: transform 1.2s cubic-bezier(0.4, 0.2, 0.2, 1);
          transition: transform 1.2s cubic-bezier(0.4, 0.2, 0.2, 1),
            -webkit-transform 1.2s cubic-bezier(0.4, 0.2, 0.2, 1);
          -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
          visibility: visible;

          text-align: center;
          height: auto;
          color: #fff;
          border-radius: 10px;
        }
        #wortise-flip-parallax-10.first-flip .front,
        #wortise-flip-parallax-10.first-flip .back {
          -webkit-transition: -webkit-transform 0.8s
            cubic-bezier(0.4, 0.2, 0.2, 1);
          transition: -webkit-transform 0.8s cubic-bezier(0.4, 0.2, 0.2, 1);
          -o-transition: transform 0.8s cubic-bezier(0.4, 0.2, 0.2, 1);
          transition: transform 0.8s cubic-bezier(0.4, 0.2, 0.2, 1);
          transition: transform 0.8s cubic-bezier(0.4, 0.2, 0.2, 1),
            -webkit-transform 0.8s cubic-bezier(0.4, 0.2, 0.2, 1);
        }

        #wortise-flip-parallax-10 .side {
          width: 100%;
          height: 100%;
          border-radius: 10px;
        }

        #wortise-flip-parallax-10 .side .bg .color-bg {
          width: 100%;
          height: 100%;
        }
        #wortise-flip-parallax-10 .side .normalize-box {
          transform: rotateZ(-180deg);
          position: relative;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          transform-style: preserve-3d;
          backface-visibility: hidden;

          -webkit-backface-visibility: hidden;
        }
        #wortise-flip-parallax-10 .side .bg {
          width: 100%;
          height: 100%;
          border-radius: 10px;
          overflow: hidden;
          backface-visibility: hidden;
          -webkit-backface-visibility: hidden;
        }
        #wortise-flip-parallax-10 .side .bg img {
          width: 100%;
          height: auto;
          display: block;
          object-fit: fill;
        }
        #wortise-flip-parallax-10 .side .overlay {
          backface-visibility: hidden;
          -webkit-backface-visibility: hidden;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          position: absolute;
          transform: translateZ(58px);
        }

        #wortise-flip-parallax-10 .front.side .overlay {
          width: 73%;
          height: 69.73%;
          top: 24%;
          left: 14%;
        }
        #wortise-flip-parallax-10 .front.side .overlay .overlay-image {
          position: absolute;
          width: 100%;
          height: 100%;
          font-family: sans-serif;
        }
        #wortise-flip-parallax-10 .front.side .overlay .overlay-image img {
          width: 100%;
          height: 100%;
          display: block;
        }

        #wortise-flip-parallax-10 .back.side .overlay {
          width: 73%;
          height: 69.73%;
          top: 24%;
          left: 14%;
        }
        #wortise-flip-parallax-10 .back.side .overlay .overlay-image {
          position: absolute;
          width: 100%;
          height: 100%;
          font-family: sans-serif;
        }
        #wortise-flip-parallax-10 .back.side .overlay .overlay-image img {
          width: 100%;
          height: 100%;
          display: block;
        }

        #wortise-flip-parallax-10 .front {
          position: relative;
          z-index: 2;
        }

        #wortise-flip-parallax-10 .back {
          width: 100%;
          height: 100%;
        }

        #wortise-flip-parallax-10 .front:after {
          position: absolute;
          top: 0;
          left: 0;
          z-index: 1;
          width: 100%;
          height: 100%;
          content: "";
          display: block;
          opacity: 0.6;
          -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
        }
        #wortise-flip-parallax-10 .back {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
        }

        #wortise-flip-parallax-10 .inner {
          -webkit-transform: translateY(-50%) translateZ(60px) scale(0.94);
          transform: translateY(-50%) translateZ(60px) scale(0.94);
          top: 50%;
          position: absolute;
          left: 0;
          width: 100%;
          padding: 2rem;
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
          outline: 1px solid transparent;
          -webkit-perspective: inherit;
          perspective: inherit;
          z-index: 2;
        }

        #wortise-flip-parallax-10 .container .back {
          -webkit-transform: rotateY(540deg);
          transform: rotateY(540deg);
          -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
        }

        #wortise-flip-parallax-10 .container .front {
          -webkit-transform: rotateY(0deg);
          transform: rotateY(0deg);
          -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
        }

        #wortise-flip-parallax-10.flip .container .back {
          -webkit-transform: rotateY(360deg);
          transform: rotateY(360deg);
          -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
        }

        #wortise-flip-parallax-10.flip .container .front {
          -webkit-transform: rotateY(-180deg);
          transform: rotateY(-180deg);
          -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
        }
      </style>

`
const rotateJS1 = `
  <script>
  isMobilecheck = function () {
    var check = false;
    (function (a) {
      if (
        /(android|bb\\d+|meego).+mobile|avantgo|bada\\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
          a
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\\-(n|u)|c55\\/|capi|ccwa|cdm\\-|cell|chtm|cldc|cmd\\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\\-s|devi|dica|dmob|do(c|p)o|ds(12|\\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\\-|_)|g1 u|g560|gene|gf\\-5|g\\-mo|go(\\.w|od)|gr(ad|un)|haie|hcit|hd\\-(m|p|t)|hei\\-|hi(pt|ta)|hp( i|ip)|hs\\-c|ht(c(\\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\\-(20|go|ma)|i230|iac( |\\-|\\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\\/)|klon|kpt |kwc\\-|kyo(c|k)|le(no|xi)|lg( g|\\/(k|l|u)|50|54|\\-[a-w])|libw|lynx|m1\\-w|m3ga|m50\\/|ma(te|ui|xo)|mc(01|21|ca)|m\\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\\-2|po(ck|rt|se)|prox|psio|pt\\-g|qa\\-a|qc(07|12|21|32|60|\\-[2-7]|i\\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\\-|oo|p\\-)|sdk\\/|se(c(\\-|0|1)|47|mc|nd|ri)|sgh\\-|shar|sie(\\-|m)|sk\\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\\-|v\\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\\-|tdg\\-|tel(i|m)|tim\\-|t\\-mo|to(pl|sh)|ts(70|m\\-|m3|m5)|tx\\-9|up(\\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\\-|your|zeto|zte\\-/i.test(
          a.substr(0, 4)
        )
      )
        check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);
    return check;
  };
</script>

`

const rotateJS2 = `
  <script>
    var component10 = document.getElementById("wortise-flip-parallax-10");
    var locked10 = false;
    var firstHover10 = true;
    var autoRotate10 = true;
    var touchStart10;
    var autolayInter10;
    var parallaxFlip10Played = false;
    var rotationGapTimeout10 = false;
    var lastFlipTime10 = Date.now();
    var firstFlip10 = true;

    function parallaxFlip10() {
      component10.classList.toggle("flip");
      lastFlipTime10 = Date.now();
    }

    if (!isMobilecheck()) {
      component10.addEventListener("mouseover", function () {
        if (firstHover10) {
          autoRotate10 = false;
          firstHover10 = false;
        }
        component10.classList.add("flip");
      });
      component10.addEventListener("mouseleave", function () {
        component10.classList.remove("flip");
      });
    } else {
      component10.addEventListener("touchstart", function (event) {
        if (firstHover10) {
          autoRotate10 = false;
          firstHover10 = false;
        }
        touchStart10 = event;
      });
      window.addEventListener(
        "touchmove",
        function (event) {
          event.preventDefault();
        },
        {
          passive: false,
        }
      );
      window.addEventListener("touchend", function (event) {
        if (touchStart10) {
          var x1 = touchStart10.touches[0].pageX;
          var x2 = event.changedTouches[0].pageX;
          var y1 = touchStart10.touches[0].pageY;
          var y2 = event.changedTouches[0].pageY;
          var distance = getDistance(x1, y1, x2, y2);
          if (distance > 40) {
            component10.classList.toggle("flip");
          }
        }
      });
    }

    function getDistance(x1, y1, x2, y2) {
      return Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));
    }

    // GIRO AUTOMÁTICO
    function addFlipClass() {
      component10.classList.add("flip");
      setTimeout(function () {
        component10.classList.remove("flip");
      }, 1000); // Cambia este valor (1000) al número de milisegundos de duración del efecto automático
    }

    // Llama a la función para añadir la clase "flip" cuando se cargue el HTML
    window.onload = function () {
      addFlipClass();
    };
  </script>
`
