import { SerializedLayer } from '@lidojs/design-core';
import { ImageLayerProps, RootLayerProps, extractText, ConfigCreative } from '@lidojs/design-editor';
import { uploadPageHTMLAndGetURL } from '../../download';
import {
  RedirectionProps,
  generateRedirectionHTML,
} from '../generateRedirectionHTML';
import { generateValidURL } from '../generateValidURL';
import { PageExport, containerPremium } from './generatePremiumHTML';
const urlDefault = process.env.REDIRECT_URL_DEFAULT;

interface IConfigCreativeColector {
  [key: string]: ConfigCreative;
}

export const generateColector = async ({
  page,
  hasMobileFrame,
  pages,
}: PageExport) => {
  const { ROOT: container, ...components } = page;

  const creativeToShow = pages.find(
    (item) =>
      (item.layers.ROOT as SerializedLayer)?.idCreative ===
      (container.props as unknown as RootLayerProps).redirectCreative.idCreative
  );
  const creativeUploaded = creativeToShow ? await uploadPageHTMLAndGetURL({
    page: creativeToShow,
    hasMobileFrame: false,
  }) : urlDefault;

  const resources = {
    image1: {
      url: (components.colector_image1.props as unknown as ImageLayerProps).image
        ?.url,
      hyperlink: components.colector_image1.props.hyperlink,
    },
    image2: {
      url: (components.colector_image2.props as unknown as ImageLayerProps).image
        ?.url,
      hyperlink: components.colector_image2.props.hyperlink,
    },
    image3: {
      url: (components.colector_image3.props as unknown as ImageLayerProps).image
        ?.url,
      hyperlink: components.colector_image3.props.hyperlink,
    },
    image4: {
      url: (components.colector_image4.props as unknown as ImageLayerProps).image
        ?.url,
      hyperlink: components.colector_image4.props.hyperlink,
    },
    image5: {
      url: (components.colector_image5.props as unknown as ImageLayerProps).image
        ?.url,
      hyperlink: components.colector_image5.props.hyperlink,
    },
    image6: {
      url: (components.colector_image6.props as unknown as ImageLayerProps).image
        ?.url,
      hyperlink: components.colector_image6.props.hyperlink,
    },
    urlIframe: creativeUploaded,
    welcomeText: extractText(components.colector_text3.props?.text as string),
    winText: extractText(components.colector_text4.props?.text as string),
    loseText: extractText(components.colector_text5.props?.text as string),
    config: {
      livesNumber: (container.props.configCreative as IConfigCreativeColector)?.livesNumber.value,
      pointsToWin: (container.props.configCreative as IConfigCreativeColector)?.pointsToWin.value,
      elementsToWin: (container.props.configCreative as IConfigCreativeColector)?.elementsToWin.value,
      elementsToLose: (container.props.configCreative as IConfigCreativeColector)?.elementsToLose.value,
    }
  };

  const redirectionContainer = generateRedirectionHTML(
    {
      props: container.props.hyperlink,
      id: 'ROOT',
    }
  );
  const containerHyperlink = container.props.hyperlink as RedirectionProps;

  return `
      <title>Creative Premium: Collector</title>
      ${(containerHyperlink?.hasHyperlink && containerHyperlink?.typeLink === "click-tag") ? (
        `<script type="text/javascript">
          var clickTag = "${generateValidURL(
            (container?.props?.hyperlink as RedirectionProps)?.linkRedirect
          )}";
        </script>`
      ) : ''}
      ${colectorStyles}

    </head>
    <body ${redirectionContainer}>
      ${containerPremium(hasMobileFrame)}
        <canvas id="gameCanvas"></canvas>
        <div id="result">
          <iframe width="100%" src="${
            resources.urlIframe
            }" style="position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          border: none;
          right: 0;
          width: 100%;
          aspect-ratio: 1080 / 1920;
          "></iframe>
        </div>
      </div>
      ${hasMobileFrame ? '</div>' : ''}
    </div>
        <script>
      const canvas = document.getElementById("gameCanvas");
      const ctx = canvas.getContext("2d");

      // Imagen de fondo
      const bgImage = new Image();
      bgImage.src = "${resources.image1.url}";

      // Imagen del jugador
      const playerImage = new Image();
      playerImage.src = "${resources.image6.url}";

      // Imagen de la manito
      const handImage = new Image();
      handImage.src = "https://creatives.wortise.com/1729612441101.png";

      // Arrays de imágenes para objetos buenos y malos
      const goodImages = [
        "${resources.image3.url}",
        "${resources.image4.url}",
        "${resources.image5.url}",
      ];
      const badImages = ["${resources.image2.url}"];

      // Cargar las imágenes
      function loadImages(imageArray) {
        return imageArray.map((src) => {
          const img = new Image();
          img.src = src;
          return img;
        });
      }

      const goodObjectsImages = loadImages(goodImages);
      const badObjectsImages = loadImages(badImages);

      // Ajustar el canvas al tamaño del contenedor
      function resizeCanvas() {
        const container = canvas.parentElement;
        canvas.width = container.clientWidth;
        canvas.height = container.clientHeight;

        player.x = canvas.width / 2 - player.width / 2; // Centrar el jugador
        player.y = canvas.height - player.height - 10;
      }

      window.addEventListener("resize", resizeCanvas);
      // resizeCanvas();

      const resultDiv = document.getElementById("result");
      // Variables del juego
      let player = {
        x: canvas.width / 2 - 50,
        y: canvas.height - 100, // Altura ajustada
        width: 100,
        height: 80,
        dx: 0,
        originalWidth: 100, // Guardar tamaño original
        originalHeight: 80, // Guardar tamaño original
        isShaking: false,
        isGrowing: false,
        shakeOffsetX: 0,
        shakeOffsetY: 0,
        isDisappearing: false, // Nueva propiedad para la desaparición
        disappearSpeed: 5, // Velocidad de desaparición hacia abajo
      };
      let objects = [];
      let score = 0;
      let lives = ${resources.config.livesNumber};
      let isMousePressed = false;
      const winningScore = ${resources.config.pointsToWin}; // Puntaje para ganar
      let gameOver = false; // Nuevo estado del juego
      let gameStarted = false;

      // Animación de la manito
      let showHand = true;
      let handXOffset = 0;
      let handDirection = 1; // Dirección de la manito (1 derecha, -1 izquierda)

      let goodObjectCount = ${resources.config.elementsToWin};
      let badObjectCount = ${resources.config.elementsToLose};
      let currentGood = 0;       // Contador de objetos buenos generados
      let currentBad = 0;        // Contador de objetos malos generados

      // Crear objeto caído (puede ser bueno o malo)
      function createFallingObject() {
        if (!gameOver) {
          let x = Math.random() * (canvas.width - 60); // Ajustar el ancho del canvas
          let y = -60; // Iniciar fuera del canvas
          let speed = Math.random() * 3 + 2; // Mantener la velocidad aleatoria

          let type, image, size;

          // Determina si generamos un objeto bueno o malo
          if (currentGood < goodObjectCount && (currentBad >= badObjectCount || Math.random() < 0.45)) {
            type = "good";
            image = goodObjectsImages[Math.floor(Math.random() * goodObjectsImages.length)];
            size = 80; // Tamaño fijo para objetos buenos
            currentGood++;
          } else if (currentBad < badObjectCount) {
            type = "bad";
            image = badObjectsImages[Math.floor(Math.random() * badObjectsImages.length)];
            size = 60; // Tamaño fijo para objetos malos
            currentBad++;
          } else {
            return;
          }

          objects.push({ x, y, size, speed, type, image });
        }
      }

      // Efecto de agitación cuando choca con un objeto malo
      function triggerShakeEffect() {
        player.isShaking = true;
        let shakeDuration = 500; // Duración en milisegundos
        const shakeStrength = 10;

        const shakeInterval = setInterval(() => {
          player.shakeOffsetX = (Math.random() - 0.5) * shakeStrength;
          player.shakeOffsetY = (Math.random() - 0.5) * shakeStrength;
        }, 50);

        setTimeout(() => {
          clearInterval(shakeInterval);
          player.isShaking = false;
          player.shakeOffsetX = 0;
          player.shakeOffsetY = 0;
        }, shakeDuration);
      }

      // Efecto de crecimiento cuando choca con un objeto bueno
      function triggerGrowEffect() {
        player.isGrowing = true;
        player.width = player.originalWidth * 1.1;
        player.height = player.originalHeight * 1.1;

        setTimeout(() => {
          player.width = player.originalWidth;
          player.height = player.originalHeight;
          player.isGrowing = false;
        }, 500); // Duración del crecimiento
      }

      // Dibujar el jugador con una imagen
      function drawPlayer() {
        // Si el jugador está desapareciendo, se mueve hacia abajo
        if (player.isDisappearing) {
          player.y += player.disappearSpeed;
        }

        // Agitar el jugador si está en modo de agitación
        if (player.isShaking) {
          ctx.save();
          ctx.translate(player.shakeOffsetX, player.shakeOffsetY);
        }

        ctx.drawImage(
          playerImage,
          player.x,
          player.y,
          player.width,
          player.height
        );

        if (player.isShaking) {
          ctx.restore(); // Restaurar posición original
        }
      }

      // Dibujar la manito animada, moviéndose de izquierda a derecha
      function drawHand() {
        if (showHand) {
          const handX = player.x + handXOffset; // Animar la manito moviéndose de izquierda a derecha sobre el jugador
          const handY = player.y + 20; // Posicionar la manito justo sobre el jugador

          ctx.drawImage(handImage, handX, handY, 50, 50); // Tamaño de la manito

          // Cambiar el offset para animar el movimiento de la manito
          handXOffset += handDirection * 2;

          // Revertir dirección si la manito sale de los límites del jugador
          if (handXOffset < -30 || handXOffset > player.width - 20) {
            handDirection *= -1;
          }
        }
      }

      // Dibujar objetos caídos con imágenes
      function drawObjects() {
        objects.forEach((obj) => {
          ctx.drawImage(obj.image, obj.x, obj.y, obj.size, obj.size);
        });
      }

      // Mover los objetos caídos
      function moveObjects() {
        objects.forEach((obj, index) => {
          obj.y += obj.speed;

          if (
            obj.y + obj.size >= player.y && // Verifica si el objeto toca al jugador
            obj.x + obj.size > player.x &&
            obj.x < player.x + player.width
          ) {
            if (obj.type === "good") {
              score++;
              triggerGrowEffect(); // Efecto de crecimiento
            } else if (obj.type === "bad") {
              lives--;
              triggerShakeEffect(); // Efecto de agitación
            }
            objects.splice(index, 1); // Eliminar el objeto capturado
          } else if (obj.y > canvas.height) {
            objects.splice(index, 1); // Eliminar objetos fuera del canvas
          }
        });
      }

      // Mover el jugador

      function movePlayer() {
        if (!player.isDisappearing) {
          // Control de movimiento horizontal
          player.x += player.dx;
          // Verificar límites izquierdo y derecho
          if (player.x < 0) {
            player.x = 0; // Limitar al borde izquierdo
          } else if (player.x + player.width > canvas.width) {
            player.x = canvas.width - player.width; // Limitar al borde derecho
          }

          // Limitar el movimiento vertical
          if (player.y < 0) {
            player.y = 0; // Limitar al borde superior
          }
        }
      }
      // Ocultar la manito cuando el usuario interactúa
      function hideHandOnUserAction() {
        showHand = false;
      }

      // Control de movimiento del jugador con el mouse
      canvas.addEventListener("mousemove", (e) => {
        if (isMousePressed) {
          const rect = canvas.getBoundingClientRect();
          const newPlayerX = e.clientX - rect.left - player.width / 2;
          if (newPlayerX > 0 && newPlayerX < canvas.width - player.width) {
            player.x = newPlayerX;
          }
          hideHandOnUserAction(); // Ocultar la manito
          gameStarted = true; // Iniciar el juego al mover el mouse
        }
      });

      // Detectar si el mouse está presionado
      canvas.addEventListener("mousedown", () => {
        isMousePressed = true;
        hideHandOnUserAction();
        gameStarted = true; // Iniciar el juego al mover el mouse
      });

      canvas.addEventListener("mouseup", () => {
        isMousePressed = false;
      });

      // Control de movimiento del jugador con touch
      canvas.addEventListener("touchmove", (e) => {
        e.preventDefault();
        const touch = e.touches[0];
        const rect = canvas.getBoundingClientRect();
        const newPlayerX = touch.clientX - rect.left - player.width / 2;
        if (newPlayerX > 0 && newPlayerX < canvas.width - player.width) {
          player.x = newPlayerX;
        }
        hideHandOnUserAction(); // Ocultar la manito
        gameStarted = true; // Iniciar el juego al mover el touch
      });

      // Dibujar el fondo
      function drawBackground() {
        ctx.drawImage(bgImage, 0, 0, canvas.width, canvas.height);
      }

      // Mover el jugador hacia abajo al perder
      function movePlayerDownAndEndGame() {
        player.isDisappearing = true; // Activar movimiento hacia abajo
        gameOver = true; // Indicar que el juego terminó
      }

      // Actualizar el juego
      function update() {
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        const isVictory = score >= winningScore;

        drawBackground();
        drawPlayer();
        drawObjects();
        moveObjects();
        movePlayer();
        drawHand(); // Dibujar la manito si está visible

        // Mostrar puntaje y vidas
        ctx.fillStyle = "white"; // Texto en blanco
        ctx.strokeStyle = "black"; // Borde negro
        ctx.lineWidth = 4; // Grosor del borde
        ctx.font = "700 20px Montserrat"; // Fuente Montserrat con peso 700


        ctx.strokeText(\`Puntaje: \${score}/\${winningScore}\`, 10, 75); // Dibuja el borde
        ctx.fillText(\`Puntaje: \${score}/\${winningScore}\`, 10, 75); // Rellena el texto
        ctx.strokeText(\`Vidas: \${lives}\`, canvas.width - 102, 75); // Dibuja el borde
        ctx.fillText(\`Vidas: \${lives}\`, canvas.width - 102, 75);

        if (!gameStarted && !gameOver && !isVictory) {
          const text = "${resources.welcomeText}";
          const maxWidth = canvas.width * 0.8;
          const words = text.split(" "); // Divide el texto en palabras
          const lines = [];
          let currentLine = "";

          ctx.font = "700 40px Montserrat"; // Fuente y estilo

          words.forEach(word => {
            const testLine = currentLine ? currentLine + " " + word : word; // Añadir palabra
            const testWidth = ctx.measureText(testLine).width; // Medir ancho

            // Si el ancho excede el límite, guardar la línea y comenzar una nueva
            if (testWidth > maxWidth) {
              lines.push(currentLine);
              currentLine = word; // Comenzar nueva línea
            } else {
              currentLine = testLine; // Continuar en la línea actual
            }
          });

          if (currentLine) {
            lines.push(currentLine);
          }

          // Dibujar el texto en el canvas
          const lineHeight = 50; // Altura de cada línea
          lines.forEach((line, index) => {
            const lineWidth = ctx.measureText(line).width; // Medir el ancho de cada línea
            const textY = canvas.height / 2 + index * lineHeight; // Ajusta la posición vertical
            ctx.strokeText(line, canvas.width / 2 - lineWidth / 2, textY);
            ctx.fillText(line, canvas.width / 2 - lineWidth / 2, textY);
          });
        }

        // Verificar si el jugador ha ganado
        if (isVictory) {
          ctx.font = "700 40px Montserrat"; // Fuente Montserrat con peso 700
          const winText = "${resources.winText}"; // Texto de victoria
          const winTextWidth = ctx.measureText(winText).width;
          const textY = canvas.height / 2;
          ctx.strokeText(winText, canvas.width / 2 - winTextWidth / 2, textY);
          ctx.fillText(winText, canvas.width / 2 - winTextWidth / 2, textY);
          movePlayerDownAndEndGame(); // Mover el jugador hacia abajo
          setTimeout(() => {
            showResult(); // Mostrar el resultado
          }, 2000); // Esperar 3 segundos antes de mostrar el resultado
        }

        // Verificar si el jugador se quedó sin vidas
        if (lives > 0) {
          requestAnimationFrame(update);
        } else {
          ctx.font = "700 40px Montserrat";
          const loseText = "${resources.loseText}"; // Texto de derrota
          const loseTextWidth = ctx.measureText(loseText).width;
          const textY = canvas.height / 2;

          ctx.strokeText(loseText, canvas.width / 2 - loseTextWidth / 2, textY);
          ctx.fillText(loseText, canvas.width / 2 - loseTextWidth / 2, textY);
          movePlayerDownAndEndGame(); // Mover el jugador hacia abajo
          requestAnimationFrame(update); // Continuar la animación para mover al jugador fuera del canvas
          setTimeout(() => {
            showResult();
          }, 2000); // Esperar 3 segundos antes de mostrar el resultado
        }
      }

      function showResult() {
        resultDiv.style.display = "block";
        setTimeout(() => {
          resultDiv.classList.add("show");
        }, 600);
      }

      document.addEventListener("DOMContentLoaded", () => {
        resizeCanvas(); // Ajusta el tamaño del canvas
        update(); // Inicia el juego
      });

      // SE DETIENE EN CASO DE QUE EL USUARIO CAMBIE DE PESTAÑA O NO VISUALICE EL DOCUMENTO
      // Crear objetos cada cierto tiempo
      let objectCreationInterval;

      function startObjectCreation() {
        objectCreationInterval = setInterval(createFallingObject, 1500);
      }

      function stopObjectCreation() {
        clearInterval(objectCreationInterval);
      }

      document.addEventListener("visibilitychange", () => {
        if (document.hidden) {
          stopObjectCreation(); // Detener la creación de objetos
        } else {
          startObjectCreation(); // Reiniciar la creación de objetos
        }
      });

      // Iniciar la creación de objetos al cargar la página
      startObjectCreation();
    </script>
  `;
};

const colectorStyles = `
    <style>
      @import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;900&display=swap");
      body {
        background-color: rgb(255, 255, 255);
      }
      #gameCanvas {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
      }
      #result {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 3;
        background-color: transparent;
        overflow: hidden;
      }

      #result iframe {
        width: 100%;
        height: 100%;
        transform: scale(0);
        transition: transform 0.6s ease-out;
      }

      #result.show iframe {
        transform: scale(1);
      }
    </style>
`;
