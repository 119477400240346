import { ImageLayerProps, VideoLayerProps } from "@lidojs/design-editor";
import { generateCarouselHTML } from "../generateCarouselHTML";
import { generatecarouselJS } from "../generateCarouselJs";
import { generateChildComponent } from "../generateChildComponent";
import { RedirectionProps, generateRedirectionHTML } from "../generateRedirectionHTML";
import { generateValidURL } from "../generateValidURL";
import { videoControlStyles } from "../stylesToExport";
import { PageExport, containerPremium } from "./generatePremiumHTML";

export const generateVideoboxCarousel =  ({page,hasMobileFrame}: PageExport) => {

  const { ROOT: container, ...components } = page;

  const resources = {
    image1: {
      url:  (components.videoboxCarousel_image1.props as unknown as ImageLayerProps).image?.url,
      hyperlink: components.videoboxCarousel_image1.props.hyperlink,
    },
    video1: {
      url:  (components.videoboxCarousel_video1.props as unknown as VideoLayerProps).video?.url,
      hyperlink: components.videoboxCarousel_video1.props.hyperlink,
    },
    carousel1: {
      props: components.videoboxCarousel_carousel1.props,
      Hyperlink: components.videoboxCarousel_carousel1.props.hyperlink,
    }
  }

  const carouselHTML = generateCarouselHTML(resources.carousel1.props, "carouselVideoboxCarousel");
  const carouselJS = generatecarouselJS(resources.carousel1.props, "carouselVideoboxCarousel");
  const carouselString = carouselHTML + carouselJS;

  const videoHTML = generateChildComponent(components.videoboxCarousel_video1, "videoBoxCarousel_video1");
  const hasVideoControls = (components.videoboxCarousel_video1.props as unknown as VideoLayerProps).video.controls


  const redirectionContainer = generateRedirectionHTML(
    {
      props: container.props.hyperlink,
      id: "ROOT"
    }
  );
  const containerHyperlink = container.props.hyperlink as RedirectionProps;

  return `
        <title>Creative Premium: Videobox Carousel</title>
              <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/swiper@8/swiper-bundle.min.css" />
      <script src="https://cdn.jsdelivr.net/npm/swiper@8/swiper-bundle.min.js"></script>

      ${(containerHyperlink?.hasHyperlink && containerHyperlink?.typeLink === "click-tag") ? (
        `<script type="text/javascript">
          var clickTag = "${generateValidURL(
            (container?.props?.hyperlink as RedirectionProps)?.linkRedirect
          )}";
        </script>`
      ) : ''}
        <style>
            * { margin: 0; padding: 0; box-sizing: border-box;}
            [data-type="SvgLayer"] svg{
              width: 100%;
              height: auto;
            }
            body{
                  background-color: rgb(0, 0, 0);
                }
            #ROOT{
               background-color: rgb(0, 0, 0)!important;
            }

          ${hasVideoControls ?
            videoControlStyles : ''
          }

        </style>
    </head>
    <body ${redirectionContainer}>

    ${containerPremium(hasMobileFrame)}
    <div data-id="4d8f8751-e8c9-43c0-8433-4f00e6ff45d3"
            data-locked="false"
            data-type="VideoLayer"
            data-child='[]'
            data-parent="ROOT"
            style="width: 100%;
            height: 31.732500499166573%;
            z-index: 1;
            position: absolute; left: 0; top: 0;">
            ${videoHTML}
          </div><div data-id="b448df19-666b-432a-a397-92a63e69163e"
            data-locked="false"
            data-type="ImageLayer"
            data-child='[]'
            data-parent="ROOT"
            class=""

            style="width: 100.29037194798323%;
  height: 45.130667376592456%;
  z-index: 2;
  position: absolute; left: 0; top: 31.94032721377189%;">
              <img src="${resources.image1.url}" alt=""
    width="100%" height="100%">
          </div><div data-id="641d2bd0-7ae8-428b-8b71-9a367f4a7288"
            data-locked="false"
            data-type="CarouselLayer"
            data-child='[]'
            data-parent="ROOT"
            class=""
            style="width: 100%;
  height: 18.178%;
  z-index: 3;
  position: absolute; left: 0; top: 81.822%;">
  ${carouselString}
          </div></div>
          ${hasMobileFrame ? "</div>" : ""}
    </div>
  `
}
