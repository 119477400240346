import { CarouselContentProps, ObjectFit } from '@lidojs/design-layers';
import {
  Checkbox,
  Input,
  Radio,
  RadioGroup,
  Select,
  SelectItem,
} from '@nextui-org/react';
import { ChangeEvent, FunctionComponent } from 'react';
import { useEditor, useSelectedLayers } from '../../hooks';
import { getRandomId } from '../../ultils/layer/layers';
import { Dropzone } from '../dropzone/Dropzone';

interface ICarouselEditor {
  carouselId?: string;
}

export const CarouselEditor: FunctionComponent<ICarouselEditor> = (props) => {
  const { selectedLayers } = useSelectedLayers();
  const { actions, activePage, pages } = useEditor((state) => ({
    pages: state.pages,
    activePage: state.activePage,
  }));

  const acceptedFormats = ["image/png", "image/jpg", "image/jpeg", "image/webp"]

  const carouselId = props?.carouselId || selectedLayers[0].id;
  const carousel = props?.carouselId
    ? (pages[activePage].layers[carouselId].data.props as CarouselContentProps)
    : (selectedLayers[0].data.props as CarouselContentProps);

  const onChangeItemsCarousel = (id: string, key, value) => {
    actions.setProp<CarouselContentProps>(activePage, carouselId, {
      itemsCarousel: carousel.itemsCarousel.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            [key]: value,
          };
        }
        return item;
      }),
    });
  };

  const onChangeCarousel = (key, value) => {
    actions.setProp<CarouselContentProps>(activePage, carouselId, {
      [key]: value,
    });
  };

  const onDelete = (id) => {
    actions.setProp<CarouselContentProps>(activePage, carouselId, {
      itemsCarousel: carousel.itemsCarousel.filter((item) => item.id !== id),
    });
  };

  const onAdd = (img: File) => {
    actions.setProp<CarouselContentProps>(activePage, carouselId, {
      itemsCarousel: [
        ...carousel.itemsCarousel,
        {
          id: getRandomId(),
          image: img,
          hasHyperlink: false,
          typeLink: 'a-href',
          linkRedirect: process.env.REDIRECT_URL_DEFAULT || '',
        },
      ],
    });
  };

  const ObjectFitOptions = Object.entries(ObjectFit).map(([label, value]) => ({
    label,
    value,
  }));

  return (
    <div css={props?.carouselId ? { padding: '24px 0' } : { padding: '24px 16px' }}>
      <div
        css={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          gap: 16,
          marginBottom: props.carouselId ? 8 : 16,
        }}
      >
        {!props.carouselId && (
          <>
            <Checkbox
              color="default"
              isSelected={carousel.autoplayCarousel}
              onValueChange={(value) =>
                onChangeCarousel('autoplayCarousel', value)
              }
            >
              Autoplay Carousel
            </Checkbox>
            <Checkbox
              color="default"
              isSelected={carousel.loopCarousel}
              onValueChange={(value) => onChangeCarousel('loopCarousel', value)}
            >
              Loop
            </Checkbox>
            <div>
              <label className="mb-2 inline-block text-[#676767]">Speed</label>
              <Input
                key="speedCarousel"
                max={10000}
                min={1000}
                name="speedCarousel"
                step={500}
                type="number"
                value={carousel?.speedCarousel?.toString() || undefined}
                variant="bordered"
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  onChangeCarousel('speedCarousel', parseInt(e.target.value))
                }
              />
            </div>
            <div>
              <label className="text-xs mb-2 inline-block text-[#676767]">
                Object fit
              </label>
              <Select
                aria-label='Object fit'
                className="max-w-xs text-black"
                color="primary"
                defaultSelectedKeys={[carousel.objectFit]}
                items={ObjectFitOptions}
                name="objectFit"
                value={carousel?.objectFit}
                variant="bordered"
                onChange={(e) => onChangeCarousel('objectFit', e.target.value)}
              >
                {(item) => (
                  <SelectItem key={item.value}>{item.label}</SelectItem>
                )}
              </Select>
            </div>
          </>
        )}
      </div>

      <div css={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 16 }}>
        {carousel?.itemsCarousel?.map((item) => (
          <div
            key={item.id}
            css={{
              display: 'flex',
              flexDirection: 'column',
              gap: 16,
            }}
          >
            <div
              css={{
                position: 'relative',
                ':hover button': {
                  display: 'flex',
                },
                ':hover img': {
                  opacity: 0.9,
                },
              }}
            >
              <Dropzone
                key={item.id}
                acceptedFormats={acceptedFormats}
                enableEdit={true}
                preview={
                  typeof item.image === 'string'
                    ? item.image
                    : URL.createObjectURL(item.image)
                }
                onChange={(file: File) => onChangeItemsCarousel(item.id, 'image', file)}
              />
              <button
                css={{
                  position: 'absolute',
                  top: '6%',
                  right: '5%',
                  color: '#fff',
                  backgroundColor: 'red',
                  padding: '5px',
                  width: '25px',
                  height: '25px',
                  border: 'none',
                  borderRadius: 16,
                  cursor: 'pointer',
                  display: 'none',
                  justifyContent: 'center',
                  alignItems: 'center',
                  opacity: 0.6,
                  ':hover': {
                    opacity: 1,
                  },
                }}
                onClick={() => onDelete(item.id)}
              >
                X
              </button>
            </div>
            <div>
              <div
                css={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 8,
                }}
              >
                <div>
                  <Checkbox
                    color="default"
                    isSelected={item.hasHyperlink}
                    onValueChange={(value) =>
                      onChangeItemsCarousel(item.id, 'hasHyperlink', value)
                    }
                  >
                    Activate hyperlink
                  </Checkbox>
                </div>
                {item.hasHyperlink && (
                  <div>
                    <RadioGroup
                      css={{ paddingLeft: 16, marginBottom: 8 }}
                      value={item.typeLink}
                      onValueChange={(value) =>
                        onChangeItemsCarousel(item.id, 'typeLink', value)
                      }
                    >
                      <Radio value="a-href">Link HREF</Radio>
                      <Radio value="click-tag">Click Tag</Radio>
                    </RadioGroup>
                    <Input
                      placeholder="Type URL"
                      size="lg"
                      value={item.linkRedirect}
                      variant="bordered"
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        onChangeItemsCarousel(
                          item.id,
                          'linkRedirect',
                          e.target.value
                        )
                      }
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        ))}
        {carouselId && (
          <Dropzone
            key={carousel?.itemsCarousel?.length || 0}
            acceptedFormats={acceptedFormats}
            onChange={(e) => onAdd(e)}
          />
        )}

      </div>
    </div>
  );
};
