import { ImageLayerProps } from "@lidojs/design-editor";
import { RedirectionProps, generateRedirectionHTML } from "../generateRedirectionHTML";
import { generateValidURL } from "../generateValidURL";
import { PageExport, containerPremium } from "./generatePremiumHTML";

export const generateFlipSlides = ({page,hasMobileFrame}: PageExport) => {
  const { ROOT: container, ...components } = page;

  const resources = {
    image1: {
      url:  (components.flipSlides_image1.props as unknown as ImageLayerProps).image?.url,
      hyperlink: components.flipSlides_image1.props.hyperlink,
    },
    image2: {
      url:  (components.flipSlides_image2.props as unknown as ImageLayerProps).image?.url,
      hyperlink: components.flipSlides_image2.props.hyperlink,
    },
    image3: {
      url:  (components.flipSlides_image3.props as unknown as ImageLayerProps).image?.url,
      hyperlink: components.flipSlides_image3.props.hyperlink,
    },
    line1:{
      color: components.flipSlides_line1.props.color,
    }
  }

  const redirectionContainer = generateRedirectionHTML(
    {
      props: container.props.hyperlink,
      id: "ROOT"
    }
  );

  const containerHyperlink = container.props.hyperlink as RedirectionProps;

  return `

    <title>Creative Premium: Flip Slides</title>
    ${(containerHyperlink?.hasHyperlink && containerHyperlink?.typeLink === "click-tag") ? (
      `<script type="text/javascript">
        var clickTag = "${generateValidURL(
          (container?.props?.hyperlink as RedirectionProps)?.linkRedirect
        )}";
      </script>`
    ) : ''}
    ${flipSlidesStyle}
    <style>

      .deslizador {
          position: absolute;
          top: 0;
          height: 100%;
          width: 4px;
          cursor: ew-resize; /* Cambiar el cursor a horizontal */
          left: 50%; /* Centrar horizontalmente */
          transform: translateX(-50%);
          background: ${resources.line1.color};
          display: flex;
          justify-content: center;
          align-items: center;
      }

      .deslizador::before {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          width: 50px;
          height: 50px;
          transform: translate(-50%, -50%);
          background-image: url(${resources.image3.url});
          background-size: cover;
          background-position: center;
          border-radius: 50%;
      }
    </style>

  </head>
  <body ${redirectionContainer}>
    ${containerPremium(hasMobileFrame)}
        <div class="comparador">
          <img class="imagen" src="${resources.image1.url}" alt="" id="img1">
          <img class="imagen" src="${resources.image2.url}" alt="" id="img2">
          <div class="deslizador" id="slider">
        </div>
      </div>
      ${hasMobileFrame ? "</div>" : ""}
    </div>
    ${flipSlidesJS1}
  `
}

const flipSlidesStyle = `
<style>
     * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
      }
      [data-type="SvgLayer"] svg {
        width: 100%;
        height: auto;
      }

      .comparador {
        position: relative;
        overflow: hidden;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .imagen {
        position: absolute;
        top: 0;
        left: 0;
        -webkit-user-select: none; /* Safari */
        -ms-user-select: none; /* IE 10 and IE 11 */
        user-select: none; /* Standard syntax */
      }

      #img1 {
          width: 100%;
          height: 100%;
      }

      #img2 {
          clip-path: inset(0 0 0 50%); /* Cambiado para recortar de izquierda a derecha */
          width: 100%;
          height: 100%;
      }
</style>

`

const flipSlidesJS1 = `
       <script>
          const slider = document.getElementById('slider');
          const img2 = document.getElementById('img2');
          const comparador = document.querySelector('.comparador');

          let isDragging = false;
          let initialX = 0;


          // Actualizar posición del deslizador
          function updateSliderPosition(x) {
              const rect = comparador.getBoundingClientRect();
              let positionX = x - rect.left;

              // Limitar el movimiento del deslizador
              if (positionX < 0) positionX = 0;
              if (positionX > rect.width) positionX = rect.width;

              img2.style.clipPath = \`inset(0 0 0 \${positionX}px)\`; // Actualizar el recorte
              // slider.style.left = \`\${positionX - 5}px\`; // Mover el deslizador
              slider.style.left = \`\${positionX}px\`; // Mover el deslizador
          }

          // Prevenir el comportamiento predeterminado de desplazamiento
          function preventDefault(e) {
              e.preventDefault();
          }

          // Eventos para mouse
          slider.addEventListener('mousedown', (e) => {
              isDragging = true;
              updateSliderPosition(e.clientX);
              window.addEventListener('mousemove', preventDefault, { passive: false });
          });

          window.addEventListener('mouseup', () => {
              isDragging = false;
              window.removeEventListener('mousemove', preventDefault);
          });

          window.addEventListener('mousemove', (e) => {
              if (isDragging) {
                  updateSliderPosition(e.clientX);
              }
          });

          // Eventos para touch
          slider.addEventListener('touchstart', (e) => {
              isDragging = true;
              updateSliderPosition(e.touches[0].clientX);
              window.addEventListener('touchmove', preventDefault, { passive: false });
          });

          window.addEventListener('touchend', () => {
              isDragging = false;
              window.removeEventListener('touchmove', preventDefault);
          });

          window.addEventListener('touchmove', (e) => {
              if (isDragging) {
                  updateSliderPosition(e.touches[0].clientX);
              }
          });
      </script>
`
