import { SerializedLayer } from '@lidojs/design-core';
import {
  ImageLayerProps,
  RootLayerProps,
} from '@lidojs/design-editor';
import { uploadPageHTMLAndGetURL } from '../../download';
import {
  RedirectionProps,
  generateRedirectionHTML,
} from '../generateRedirectionHTML';
import { generateValidURL } from '../generateValidURL';
import { PageExport, containerPremium } from './generatePremiumHTML';
const urlDefault = process.env.REDIRECT_URL_DEFAULT;

export const generateScratch = async ({
  page,
  hasMobileFrame,
  pages,
}: PageExport) => {
  const { ROOT: container, ...components } = page;

  const creativeToShow = pages.find(
    (item) =>
      (item.layers.ROOT as SerializedLayer)?.idCreative ===
      (container.props as unknown as RootLayerProps).redirectCreative.idCreative
  );
  const creativeUploaded = creativeToShow ? await uploadPageHTMLAndGetURL({
    page: creativeToShow,
    hasMobileFrame: false,
  }) : urlDefault;

  const resources = {
    image1: {
      url: (components.scratch_image1.props as unknown as ImageLayerProps).image
        ?.url,
      hyperlink: components.scratch_image1.props.hyperlink,
    },
    image2: {
      url: (components.scratch_image2.props as unknown as ImageLayerProps).image
        ?.url,
      hyperlink: components.scratch_image2.props.hyperlink,
    },
    image3: {
      url: (components.scratch_image3.props as unknown as ImageLayerProps).image
        ?.url,
      hyperlink: components.scratch_image3.props.hyperlink,
    },
    urlIframe: creativeUploaded,
  };

  const redirectionContainer = generateRedirectionHTML(
    {
      props: container.props.hyperlink,
      id: 'ROOT',
    }
  );
  const containerHyperlink = container.props.hyperlink as RedirectionProps;

  return `
      <title>Creative Premium: Scratch</title>
      ${(containerHyperlink?.hasHyperlink && containerHyperlink?.typeLink === "click-tag") ? (
        `<script type="text/javascript">
          var clickTag = "${generateValidURL(
            (container?.props?.hyperlink as RedirectionProps)?.linkRedirect
          )}";
        </script>`
      ) : ''}
      ${scratchStyles}

    </head>
    <body ${redirectionContainer}>

      ${containerPremium(hasMobileFrame)}
        <div id="container">
          <div class="box">
            <div class="middle-box"><img src="${resources.image1.url}" width="100%"/> </div>
            <div class="container-canvas">
              <div id="hand">
                <img
                  width="100%"
                  src="https://creatives.wortise.com/1729612441101.png"
                  alt=""
                />
              </div>

              <canvas id="canvas"></canvas>
            </div>
            <div class="image-back">
              <img
                src="${resources.image3.url}"
                alt="Image back"
              />
            </div>
          </div>
        </div>

        <div id="result">

          <iframe width="100%" src="${
            resources.urlIframe
          }" style="position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        border: none;
        right: 0;
        width: 100%;
        aspect-ratio: 1080 / 1920;
        "></iframe>

        </div>
      </div>
      ${hasMobileFrame ? '</div>' : ''}
    </div>
    ${scratchJS(resources.image2.url as string)}
  `;
};

const scratchStyles = `
    <style>
      img {
        display: block;
        width: 100%;
        height: 100%;
      }

      #container {
        width: 100%;
        height: 100%;
        opacity: 1;
        transition: opacity 0.6s ease-out;
      }

      #container.hide {
        opacity: 0;
      }

      #result {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 3;
        background-color: transparent;
        overflow: hidden;
      }

      .middle-box {
        display: inline-block;
        z-index: 1;
        width: 100%;
        height: 100%;
      }

      #result iframe {
        width: 100%;
        height: 100%;
        transform: scale(0);
        transition: transform 0.6s ease-out;
      }

      #result.show iframe {
        transform: scale(1);
      }

      .box {
        display: flex;
        width: 100%;
        height: 100%;
        position: relative;
      }

      .image-back{
        position: absolute;
        left: 17.8%;
        top: 50.4%;
        width: 64.4%;
        height: 39.9%;
        z-index: 2;
        border-radius: 8%;
        overflow: hidden;
      }

      .container-canvas {
        background: transparent;
        position: absolute;
        left: 17.5%;
        top: 50.4%;
        width: 65%;
        height: 39.9%;
        z-index: 3;
      }

      #hand {
        position: absolute;
        top: 40%;
        left: 40%;
        width: 30%;
        z-index: 3;
        animation: moveHand 1.2s infinite alternate;
        pointer-events: none;
      }
      @keyframes moveHand {
        0% {
          top: 60%;
        }
        100% {
          top: 15%;
        }
      }
    </style>
`;

const scratchJS = (imgForScratch: string) => `
    <script>
      function resizeCanvas(canvas) {
        const parent = canvas.parentNode;
        canvas.width = parent.clientWidth;
        canvas.height = parent.clientHeight;
      }

      function drawImageOnCanvas(canvas, img) {
        const ctx = canvas.getContext("2d");
        resizeCanvas(canvas);
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
      }

      function scratch(e) {
        const x =
          e.offsetX ||
          e.touches[0].clientX - e.target.getBoundingClientRect().left;
        const y =
          e.offsetY ||
          e.touches[0].clientY - e.target.getBoundingClientRect().top;

        ctx.globalCompositeOperation = "destination-out";
        ctx.beginPath();
        ctx.arc(x, y, 20, 0, 2 * Math.PI);
        ctx.fill();

        if (old) {
          ctx.lineWidth = 40;
          ctx.beginPath();
          ctx.moveTo(old.x, old.y);
          ctx.lineTo(x, y);
          ctx.stroke();
        }

        old = { x: x, y: y };

        updateScratchPercentage();
      }

      function updateScratchPercentage() {
        const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
        const resultDiv = document.getElementById("result");
        const containerDiv = document.getElementById("container");
        const totalPixels = imageData.data.length / 4;
        let scratchedPixels = 0;

        for (let i = 0; i < imageData.data.length; i += 4) {
          const alpha = imageData.data[i + 3];
          if (alpha === 0) {
            scratchedPixels++;
          }
        }

        const percentage = (scratchedPixels / totalPixels) * 100;

        if (percentage > 40) {
          containerDiv.classList.add("hide");

          setTimeout(() => {
            containerDiv.style.display = "none";
            resultDiv.style.display = "block";
            resultDiv.classList.add("show");
          }, 600);
        }
      }

      function hideHand() {
        document.getElementById("hand").style.display = "none";
      }

      var url = "${imgForScratch}";
      var canvas = document.getElementById("canvas");
      var ctx = canvas.getContext("2d");
      var img = new Image();
      var isPress = false;
      var old = null;

      img.src = url;
      img.crossOrigin = "Anonymous";
      img.onload = function () {
        drawImageOnCanvas(canvas, img);
      };

      canvas.addEventListener("mousedown", function (e) {
        isPress = true;
        old = { x: e.offsetX, y: e.offsetY };
        hideHand();
      });

      canvas.addEventListener("touchstart", function (e) {
        isPress = true;
        old = {
          x: e.touches[0].clientX - e.target.getBoundingClientRect().left,
          y: e.touches[0].clientY - e.target.getBoundingClientRect().top,
        };
        e.preventDefault();
        hideHand();
      });

      canvas.addEventListener("mousemove", function (e) {
        if (isPress) scratch(e);
      });

      canvas.addEventListener("touchmove", function (e) {
        if (isPress) scratch(e);
        e.preventDefault();
      });

      canvas.addEventListener("mouseup", function (e) {
        isPress = false;
      });

      canvas.addEventListener("touchend", function (e) {
        isPress = false;
        e.preventDefault();
      });

      window.addEventListener("resize", function () {
        drawImageOnCanvas(canvas, img);
      });
    </script>
`;
